/* eslint-disable */
// @ts-nocheck

import React,
{
  useEffect, useCallback, useState, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Alert, Col, Form, Row
} from 'react-bootstrap';
import moment from 'moment';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import {
  fetchGetWorkflowsTypesAllFields,
  fetchPathWorkflowsToApplyField,
  fetchPostWorkflowsToApplyField,
  fetchClientAccounts
} from '@core/services/rootnet-service';

import { processQuestionsList } from '@modules/team_feedback_forms/utils';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './styles.scss';

const {
  workflowTypeId,
  workflowTypeFields,
  workflowEmailTypeFieldId,
  workflowFullNameTypeFieldId,
  workflowCustomerAccountTypeFieldId
} = require('@modules/team_feedback_forms/config');

const CACHE = {};

function makeAndHandleRequest(query: string) {
  return fetchClientAccounts(query);
}

export default function TeamFeedbackFormPage() {
  const { t } = useTranslation();

  const dynamicRefs = useRef(null);

  const initialState = {
    id: '',
    customerAccount: '',
    fullName: '',
    email: '',
    created_at: '',
    metas: []
  };

  const [formState, setFormState] = useState({
    workflow: { ...initialState },
    questions: [],
    workflowTypeFieldId: '',
    isLoading: false,
    variant: '',
    message: '',
    token: '',
    expired: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const typeaheadRef = useRef(null);

  const handleInputChange = (q: string) => {
    setQuery(q);
  };

  const handlePagination = () => {
    const cachedQuery = CACHE[query];

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    makeAndHandleRequest(query).then((resp) => {
      const concatOptions = cachedQuery.options.concat(resp.options);
      CACHE[query] = { ...cachedQuery, concatOptions, page };

      setIsLoading(false);
      setOptions(concatOptions);
    });
  };

  const handleSearch = useCallback((q: string) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].options);
      return;
    }

    setIsLoading(true);
    makeAndHandleRequest(q).then((resp) => {
      CACHE[q] = { ...resp, page: 1 };

      setIsLoading(false);
      setOptions(resp.options);
    });
  }, []);

  const handleTypeAheadChange = (selectedOptions: any[]) => {
    setFormState({
      ...formState,
      workflow: {
        ...formState.workflow,
        customerAccount: JSON.stringify(selectedOptions[0])
      }
    });
  };

  const onSubmitForm = async () => {
    setFormState({ ...formState, variant: '', isLoading: true });
    const dateCreate = moment(new Date());

    const extraMetas = [
      {
        value: `"${formState.workflow?.fullName}"`,
        workflow_type_field_id: workflowFullNameTypeFieldId
      },
      {
        value: `"${formState.workflow?.email}"`,
        workflow_type_field_id: workflowEmailTypeFieldId
      },
      {
        value: formState.workflow?.customerAccount,
        workflow_type_field_id: workflowCustomerAccountTypeFieldId
      }
    ];

    let parsedData = {
      workflow_type_id: workflowTypeId,
      title: `Feedback from ${formState.workflow?.fullName}`,
      start: dateCreate.format('YYYY-MM-DD'),
      workflow_metas: (formState.questions as any[]).map((q) => ({
        workflow_type_field_id: q.workflowTypeFieldId,
        value:
          q.data[0].type_info === 'textbox'
            ? `"<p>${q.data[0]['1']}</p>"`
            : JSON.stringify(q.data)
      })),
      description: '<p><span style="color: rgb(0, 0, 0);"></span></p>',
      created_at: dateCreate.format('YYYY-MM-DD')
    };
    parsedData.workflow_metas = parsedData.workflow_metas.concat(extraMetas);

    let postWorkflow;
    if (formState.workflow.id) {
      const payload = {
        workflow_id: formState.workflow.id,
        workflow_metas: (formState.questions as any[]).map((q) => ({
          workflow_type_field_id: q.workflowTypeFieldId,
          value:
            q.data[0].type_info === 'textbox'
              ? `"<p>${q.data[0]['1']}</p>"`
              : JSON.stringify(q.data)
        }))
      };
      payload.workflow_metas = payload.workflow_metas.concat(extraMetas);

      postWorkflow = fetchPathWorkflowsToApplyField(
        formState.workflow.id,
        JSON.stringify(payload)
      );
    } else {
      postWorkflow = fetchPostWorkflowsToApplyField(parsedData);
    }

    try {
      await postWorkflow;
      setFormState({
        ...formState,
        workflow: {
          email: '',
          customerAccount: '',
          fullName: ''
        },
        variant: 'success',
        message: t('TeamCustomerFeedbackThanks')
      });

      typeaheadRef.current.clear();
      dynamicRefs.current.value = null;

      document.querySelectorAll('input[type=radio]').forEach((el) => el.checked = false);
    } catch (e) {
      setFormState({
        ...formState,
        variant: 'danger',
        message: t(
          formState.workflow.id
            ? 'Customer Failed Update'
            : 'Customer Failed Create'
        )
      });
      setFormState((prevState) => ({ ...prevState, isLoading: false }));
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const setValueQuestion = (segmentIndex, arrayIndex, answerId, value) => {
    formState.questions[segmentIndex].data[arrayIndex][`${answerId + 1}`] =
      value;
    formState.questions[segmentIndex].data[arrayIndex].question.answers[
      answerId + 1
    ].value = value;
    setFormState({
      ...formState,
      questions: [...formState.questions]
    });
  };

  const fetchQuestionsWorkflow = async () => {
    try {
      setFormState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchGetWorkflowsTypesAllFields();
      const { quiz } = processQuestionsList(
        response,
        workflowTypeId,
        workflowTypeFields
      );
      setFormState((prevState) => ({
        ...prevState,
        questions: quiz
      }));
    } catch (e) {
      setFormState((prevState) => ({
        ...prevState,
        variant: 'danger',
        message: `fetchQuestionsWorkflow: ${e.message}`
      }));
    } finally {
      setFormState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const errorField = (fieldNameCleaned) => (errors[fieldNameCleaned] ? 'border-danger' : '');

  useEffect(() => {
    fetchQuestionsWorkflow();
  }, []);

  const renderQuestionsStep = (
    answer,
    indexQuiz: number,
    arrayIndex,
    index,
    question
  ) => {
    if (answer.configuration.type_info.type === 'checkbox') {
      return (
        <div
          key={answer.uuid}
          className="form-check form-check-inline mx-4 my-2"
        >
          <input
            className="form-check-input"
            type="radio"
            name={question}
            id={answer.uuid}
            ref={dynamicRefs}
            required
            onChange={(event) => {
              setValueQuestion(
                indexQuiz,
                arrayIndex,
                index,
                event.target.checked
              );
            }}
          />
          <label className="ml-2 form-check-label" htmlFor={answer.uuid}>
            {t(answer.fieldNameCleaned)}
          </label>
        </div>
      );
    }
    return (
      <div className="mx-4 my-2" key={answer.uuid}>
        <input
          id={answer.uuid}
          ref={dynamicRefs}
          type="text"
          className="form-control"
          onChange={(event) => {
            setValueQuestion(indexQuiz, arrayIndex, index, event.target.value);
          }}
        />
      </div>
    );
  };

  return (
    <>
      {formState.workflow.id && (
        <div className="container rounded-lg bg-light p-md-4 mt-4 shadow-sm border">
          <h5>{formState.workflow.customerAccount}</h5>
          <h6>
            {`${t('Team Feedback Creation date')}:`}
            <span className="font-weight-normal">
              {' '}
              {moment(formState.workflow.created_at).format('DD-MM-YYYY')}
            </span>
          </h6>
        </div>
      )}
      <div className="container py-5 position-relative">
        {formState.isLoading && (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center fixed-top">
            <div className="spinner-border text-primary" role="status" />
          </div>
        )}
        {formState.variant && (
          <Alert variant={formState.variant}>{formState.message}</Alert>
        )}
        <form onSubmit={handleSubmit(onSubmitForm)} id="">
          <div className="col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
            {!formState.workflow.id && (
              <>
                <h4 className="text-bluish">{t('TeamFeedbackFormTitle')}</h4>
                <br />
                <p className="font-weight-normal text-secondary">
                  {t('TeamCustomerFeedbackIntro1')}
                </p>
                <p className="font-weight-normal text-secondary">
                  {t('TeamCustomerFeedbackIntro2')}
                </p>
                <br />
                <Form.Group as={Row} controlId="formPlaintextTitle">
                  <Form.Label column sm="4">
                    {t('Team Feedback Title')}
                  </Form.Label>
                  <Col sm="8">
                    <AsyncTypeahead
                      id="typeahead-id"
                      ref={typeaheadRef}
                      isLoading={isLoading}
                      labelKey="company"
                      maxResults={15}
                      minLength={2}
                      onInputChange={handleInputChange}
                      onPaginate={handlePagination}
                      onSearch={handleSearch}
                      onChange={handleTypeAheadChange}
                      options={options}
                      paginate
                      inputProps={{ required: true }}
                      renderMenuItemChildren={(option) => (
                        <div key={option.id}>
                          <span>{option.company}</span>
                        </div>
                      )}
                      useCache={false}
                    />
                    <ErrorFieldAlert fieldName="title" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextTitle">
                  <Form.Label column sm="4">
                    {t('Team Feedback Contact')}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      id="input-contact"
                      className={`form-control ${errorField('contact')}`}
                      type="text"
                      {...register('contact', { required: true })}
                      value={formState?.workflow?.fullName}
                      onChange={(event) => {
                        setFormState({
                          ...formState,
                          workflow: {
                            ...formState.workflow,
                            fullName: event.target.value
                          }
                        });
                      }}
                    />
                    <ErrorFieldAlert fieldName="contact" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextTitle">
                  <Form.Label column sm="4">
                    {t('Team Feedback Email')}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      id="input-email"
                      className={`form-control ${errorField('email')}`}
                      type="text"
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Enter a valid email address'
                        }
                      })}
                      value={formState?.workflow?.email}
                      onChange={(event) => {
                        setFormState({
                          ...formState,
                          workflow: {
                            ...formState.workflow,
                            email: event.target.value
                          }
                        });
                      }}
                    />
                    <ErrorFieldAlert fieldName="email" />
                  </Col>
                </Form.Group>
              </>
            )}
          </div>
          <div className="stepper d-flex flex-column mt-5 ml-2">
            {(formState.questions as any[])
              .map((segment, segmentIndex) => segment.data.map((question, arrayIndex) => (
                <div
                  className="d-flex mb-1"
                  key={`${segment.workflowTypeFieldId}-${question.uuid}`}
                >
                  <div className="d-flex flex-column pr-4 align-items-center">
                    <div className="rounded-circle stepper-circle text-center" />
                    <div className="line h-100" />
                  </div>
                  <div className="pt-3 pb-4 w-100">
                    <h5 className="text-dark">
                      {t(question.question.askCleaned)}
                    </h5>
                    <div className="lead text-muted pb-3 my-4">
                      {question.question.answers
                        .slice(1)
                        .map((answer, index) => renderQuestionsStep(
                          answer,
                          segmentIndex,
                          arrayIndex,
                          index,
                          question.uuid2
                        ))}
                    </div>
                  </div>
                </div>
              )))}
          </div>
          <div className="text-align-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formState.isLoading}
            >
              {t('Team Feedback Finish')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

type ErrorFieldAlertProps = {
  errors: any
  fieldName: string
}

function ErrorFieldAlert({ errors, fieldName }: ErrorFieldAlertProps) {
  const { t } = useTranslation();

  return (
    errors && errors[fieldName] ? (
      <span className="error text-danger">
        {errors[fieldName]?.message
          ? t(errors[fieldName]?.message)
          : t('Required field')}
      </span>
    ) : null
  );
}
