const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const isDevelopment = activeEnv === 'development';

// id workflow type
let workflowTypeId = 85;
let workflowEmailTypeFieldId = 2135;
let workflowFullNameTypeFieldId = 2136;
let workflowCustomerAccountTypeFieldId = 2134;

let workflowTypeFields = [
  {
    id: 2137,
    name: 'Form - Block 1 Ethics1367'
  },
  {
    id: 2138,
    name: 'Form - Block 2 Communication1368'
  },
  {
    id: 2139,
    name: 'Form - Block 3 Attention1369'
  },
  {
    id: 2170,
    name: 'Please share with us any comments or suggestions regarding our services and the staff assigned to your account.1396'
  }
];

if (isDevelopment) {
  workflowTypeId = 85;
  workflowEmailTypeFieldId = 2135;
  workflowFullNameTypeFieldId = 2136;
  workflowCustomerAccountTypeFieldId = 2134;

  workflowTypeFields = [
    {
      id: 2137,
      name: 'Form - Block 1 Ethics1367'
    },
    {
      id: 2138,
      name: 'Form - Block 2 Communication1368'
    },
    {
      id: 2139,
      name: 'Form - Block 3 Attention1369'
    },
    {
      id: 2170,
      name: 'Please share with us any comments or suggestions regarding our services and the staff assigned to your account.1396'
    }
  ];
}

exports.workflowTypeId = workflowTypeId;
exports.workflowTypeFields = workflowTypeFields;
exports.workflowFullNameTypeFieldId = workflowFullNameTypeFieldId;
exports.workflowEmailTypeFieldId = workflowEmailTypeFieldId;
exports.workflowCustomerAccountTypeFieldId = workflowCustomerAccountTypeFieldId;
