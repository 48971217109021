// @ts-nocheck

function processQuestionsList(
  response,
  workflowTypeId,
  workflowTypeFields
): { quiz: any[] } {
  const teamFeedback = response.list.find(
    (value) => value.id === workflowTypeId
  );
  const { fields } = teamFeedback;

  let masterquiz = [];
  workflowTypeFields.forEach((element, masterIndex) => {
    const field = fields.find((f) => f.machine_name === element.name);
    const fieldConfig = JSON.parse(field.field_config);

    let localquiz;
    if (fieldConfig.presetRows) {
      localquiz = {
        workflowTypeFieldId: element.id,
        data: fieldConfig.presetRows.map((value, indexVal) => ({
          ...value,
          uuid: `${indexVal}-quiz`,
          uuid2: `${masterIndex}-${indexVal}-quiz`,
          type_info: 'checkbox',
          question: {
            ask: value['0'],
            askCleaned: value['0'].replace('.', ''),
            answers: fieldConfig.subFields.map((answer, index) => ({
              uuid: `${masterIndex}-${indexVal}-${index}-answer`,
              fieldName: answer.field_name,
              fieldNameCleaned: answer.field_name.replace('.', ''),
              configuration: answer.configuration,
              required: answer.required,
              value: value[index]
            }))
          }
        }))
      };
    } else {
      localquiz = {
        workflowTypeFieldId: element.id,
        data: [
          {
            uuid: '99-quiz',
            type_info: 'textbox',
            question: {
              ask: field.field_name,
              askCleaned: field.field_name.replace('.', ''),
              answers: [
                {
                  uuid: '99-0-answer'
                },
                {
                  uuid: '99-1-answer',
                  configuration: { type_info: { type: 'textbox' } },
                  required: false,
                  value: ''
                }
              ]
            }
          }
        ]
      };
    }
    masterquiz.push(localquiz);
  });
  const quiz = masterquiz;
  return { quiz };
}

function processForm(response, workflowTypeFields) {
  let quiz = [];
  const { metas } = response.workflow;
  workflowTypeFields.forEach((element) => {
    const metaInfo = metas.find(
      (m) => m.workflow_type_field_machine === element.id
    );

    if (metaInfo) {
      const values = JSON.parse(metaInfo.value);
      const configFields = JSON.parse(
        metaInfo.workflow_type_field_config
      ).subFields;
      const localquiz = values.map((value, indexVal) => ({
        workflowTypeFieldId: element.id,
        data: {
          ...value,
          uuid: `${indexVal}-quiz`,
          question: {
            ask: value['0'],
            answers: configFields.map((answer, index) => ({
              uuid: `${indexVal}-${index}-answer`,
              fieldName: answer.field_name,
              configuration: answer.configuration,
              required: answer.required,
              value: value[index]
            }))
          }
        }
      }));
      quiz.concat(localquiz);
    }
  });

  return quiz;
}

export { processQuestionsList, processForm };
